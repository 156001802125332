<template>
  <div>
    <p class="text-2xl mb-6">Upload du CA consolidé</p>
    <v-card class="pt-7 pb-3">
      <v-row class="ma-3">
        <v-card-text>
          <v-form ref="siteForm">
            <v-row>
              <v-col cols="6">
                <date-filter
                  store="finance"
                  format="month"
                  :isRange="false"
                  periodType="past-only"
                  storeGetter="getMonthDate"
                  storeUpdater="updateMonthDate"
                ></date-filter>
              </v-col>

              <v-col cols="6">
                <div class="mb-4 d-flex align-center">
                  <v-file-input
                    accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    class="ml-3 mr-3"
                    outlined
                    label="conso_financiere.xlsx"
                    v-model="file"
                  />
                  <!-- @change="onFileChange" -->
                </div>
              </v-col>

              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="saveSite"
                >
                  Enregistrer les CA consolidés</v-btn
                >
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  :to="{ name: 'finance-list-site-ca' }"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { computed, onMounted, reactive } from "@vue/composition-api";

import DateFilter from "@/components/common/filters/DateFilter";
import useAxios from "@/hooks/useAxios";
import useProxy from "@/hooks/useProxy";
import {
  daysNumberBetween,
  getDate,
  getDateStr,
  getMonthDateStr,
  getStartOfMonth,
} from "@/utils/dates";

export default {
  name: "UploadSiteConsolidatedCa",
  components: {
    DateFilter,
  },
  setup(props, { emit }) {
    const { store, router } = useProxy();
    const { axiosPost } = useAxios();

    const file = reactive({});

    // =>  METHODS
    const saveSite = async () => {
      if (!file?.name) {
        alert("Please select a file first!");

        return;
      }

      const formData = new FormData();
      formData.append("month_date", monthDate);
      formData.append("file", file, file.name);
      const { status } = await axiosPost(
        "/sites/consolidated-ca/",
        formData
        /* {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        } */
      );
      if (status === 200) {
        await router.push({ name: "finance-list-site-ca" });
      }
    };

    // =>  COMPUTED
    const monthDate = computed(() => store.getters["finance/getMonthDate"]);

    // =>  WATCH

    // =>  HOOKS
    onMounted(async () => {
      const today = getDate();
      const startOfMonthDate = getStartOfMonth(getDateStr());
      const diff = daysNumberBetween(startOfMonthDate, today);
      const lastDayOfPrevMonthDate = getDate(diff * -1);

      await store.dispatch(
        "finance/updateMonthDate",
        getMonthDateStr(lastDayOfPrevMonthDate)
      );
    });

    // =>  DATA
    return {
      file,
      saveSite,
    };
  },
};
</script>

<style scoped></style>
